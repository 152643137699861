<template>
  <div class="form">
    <img
      class="w-48 h-48 mb-10"
      src="../../assets/img/CILO_logo_symbol.png"
      alt="../assets/logo.png"
    />
    <div class="center -mt-10">
      <label class="title text-parula-dark">Email Verification Complete</label>
      <label v-if="error_msg===''" class="sub-title font-inter text-parula-dark">
        Thank you.
      </label>
      <label v-else class="sub-title font-inter text-parula-dark">
        {{error_msg}}
      </label>
    </div>
    <button class="btn font-inter bg-parula-dark" @click="onHome">
      Go CILO
    </button>
  </div>
</template>

<script>

import OpenapiAuthService from "../../services/openapiAuth.service"

export default {
  data: function() {
    return {
      error_msg: "",
    }
  },
  created() {
      const path = this.$route.path.split("/");

      const id = path[3];
      const secret = path[4];

      OpenapiAuthService.emailConfirm(id, secret)
      .then((res) =>{
        // console.log("res", res);
        this.error_msg = "";
      })
      .catch((e) => {
        // console.log("e ==>", e.response.data)
        this.error_msg = e.response.data;
      });

  },
  methods: {
    onHome: function () {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}

.center {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: xx-large;
  text-align: center;
  margin-top: 22px;
}

.sub-title {
  font-size: large;
  text-align: center;
  margin-top: 12px;
}

.btn {
  border: none;
  width: 10%;
  height: 2.5em;
  margin-top: 2.5rem;
}
</style>
